import { Link } from '@remix-run/react';
import type { RemixLinkProps } from '@remix-run/react/dist/components';

import type { AnchorHTMLAttributes } from 'react';

import { cn } from '~/utils/css';

export const baseStyles =
  'border-b text-brand-600 dark:text-brand-700 dark:border-zinc-700 transition duration-100 hover:border-b-transparent';

/**
 * Handle creating a new text link component that will be styled correctly
 * for all links that lie within a paragraph.
 *
 * @param props The options required to create the component.
 *
 * @returns A new `TextLink` component.
 */
export function TextLink(props: RemixLinkProps) {
  const { to, className, children, ...restProps } = props;

  return (
    <Link to={to} className={cn(baseStyles, className)} {...restProps}>
      {children}
    </Link>
  );
}

/**
 * Handle creating a new external link component that will be styled correctly
 * for all links that lie within a paragraph.
 *
 * @param props The options required to create the component.
 *
 * @returns A new `ExternalLink` component.
 */
export function ExternalLink(props: AnchorHTMLAttributes<HTMLAnchorElement>) {
  const { className, children, ...restProps } = props;

  return (
    <a className={cn(baseStyles, className)} {...restProps}>
      {children}
    </a>
  );
}
